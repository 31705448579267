















































































import { Watch, Component, Mixins, Prop } from 'vue-property-decorator'
import BrizaMixin from '@/mixins'
import { Address } from '@/models/questionnaire'
import { binderModule } from '@/store/store-accessor'
import { XIcon } from 'vue-feather-icons'
import TextInput from '@/components/controls/text-input/index.vue'
import { GeometryInfo } from '@/models/address'
import Map from '@/components/address/map.vue'
import ManualAddress from '@/components/address/manual-address.vue'
import ManualAddressToggle from '@/components/address/manual-address-toggle.vue'
import MapSearchBar from '@/components/address/map-search-bar.vue'

@Component({
  name: 'AddressComponent',
  components: { Map, ManualAddress, MapSearchBar, XIcon, ManualAddressToggle, TextInput }
})
export default class AddressComponent extends Mixins(BrizaMixin) {
  $refs!: {
    mapSearchBar: HTMLFormElement
  }

  @Prop() error!: string
  @Prop() controlId!: string
  @Prop({ default: false }) isReviewing!: boolean

  isManual = false

  latitude: number | null = null
  longitude: number | null = null
  unitNumber: string | undefined = ''

  address: Address = {
    street: '',
    secondary: '',
    city: '',
    region: '',
    postalCode: '',
    county: '',
    country: 'US'
  }

  // watching for value changes and populate google auto complete, and redrawing map from save geometry info
  @Watch('currentAddress')
  onCurrentAnswerChanged (val: Address | null) {
    if (val) {
      const geometryInfo = binderModule.locationGeometry.filter(geometryInfo => geometryInfo.street === val.street)
      if (geometryInfo.length > 0) this.setLatitudeAndLongitude(geometryInfo[0])
    }
  }

  handleInputChange () {
    this.latitude = null
    this.longitude = null

    this.onAddressChange({
      ...this.address,
      ...(this.unitNumber) && {
        secondary: this.unitNumber
      }
    })
  }

  label () {
    return this.question.questionWithinGroup || this.question.question
  }

  getFullAddress () {
    const { street, city, region } = this.address
    return `${street} ${city} ${region}`.trim()
  }

  setLatitudeAndLongitude (data: GeometryInfo) {
    // save geometry info to redraw map after reshuffle.
    if (!data.geometry) return
    this.longitude = data.geometry.location.lng()
    this.latitude = data.geometry.location.lat()
  }

  handleManualClick () {
    this.isManual = !this.isManual
    this.$nextTick(() => {
      if (this.isManual) {
        this.$refs.mapSearchBar.$el.focus()
      }
    })
  }

  onAddressChange (currentAddress: Address) {
    const expectedAddress = {
      ...currentAddress,
      ...(currentAddress.unitNumber && {
        secondary: currentAddress.unitNumber
      })
    }

    this.address = currentAddress
    this.isValid(expectedAddress)
    this.sendAnswer(expectedAddress)
  }

  shouldEnableRemoveLocation (): boolean {
    return this.question.id !== 'Location1Address'
  }

  /**
   * Remove the desired location
   * Shuffle the answers up by 1 if needed
   * Decrement NumberOfLocations bu 1
   */
  removeLocation () {
    binderModule.decrementLocation(this.question.id)
  }
}
