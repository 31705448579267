import VueScrollTo from 'vue-scrollto'

const DEFAULT_SCROLL_DURATION = 500

const DEFAULT_SCROLL_OPTIONS = {
  container: 'body',
  easing: 'ease-in',
  force: true
}

export function scrollToSection (sectionId: string) {
  VueScrollTo.scrollTo(`#${sectionId}`, DEFAULT_SCROLL_DURATION, {
    ...DEFAULT_SCROLL_OPTIONS,
    offset: -155
  })
}

export function scrollToQuestion (questionId: string) {
  return new Promise((resolve) => {
    VueScrollTo.scrollTo(`[data-qid=${questionId}]`, DEFAULT_SCROLL_DURATION, {
      ...DEFAULT_SCROLL_OPTIONS,
      offset: -230,
      onDone: resolve
    })
  })
}
