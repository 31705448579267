





















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'Map',
  props: {
    longitude: {
      type: Number,
      required: true
    },
    latitude: {
      type: Number,
      required: true
    }
  }
})
