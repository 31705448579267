
































import { defineComponent, computed } from '@vue/composition-api'
import { renderMarkdown } from '@/helpers/markdown'
import ReviewEditQuestionToggle from '@/components/review/review-edit-question-toggle.vue'
import { binderModule } from '@/store/store-accessor'

export default defineComponent({
  name: 'QuestionGroupLabel',
  components: {
    ReviewEditQuestionToggle
  },
  props: {
    isApplication: {
      type: Boolean,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    isReviewing: {
      type: Boolean,
      default: false
    },
    isHovered: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const markDown = (input: string) => {
      return renderMarkdown(input)
    }

    const toggleEditQuestion = () => {
      binderModule.setEditQuestion(props.item)
    }

    const isEditingQuestion = computed(() => {
      return binderModule.toggleEditQuestion
    })

    return {
      markDown,
      toggleEditQuestion,
      isEditingQuestion
    }
  }
})
