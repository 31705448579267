var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full"},[_c('transition',{attrs:{"name":"q-fade","mode":"out-in"}},[_c('div',{staticClass:"relative flex w-full question-wrapper"},[_c('div',{staticClass:"flex flex-col w-full ",class:[ _vm.showHint && !_vm.isEditingQuestion ? 'lg:w-1/2':''],style:({width: _vm.isReviewing ? '100%':''})},[(_vm.currentQuestion.type === 'Address')?_c('div',[_vm._t("question-wording"),_vm._t("question-control")],2):_c('div',[(_vm.group)?_c('div',{staticClass:"question-layout_group",class:[ _vm.isReviewing ? '' : 'pb-30']},[_c('div',{staticClass:"flex question-group border-0 relative",class:[_vm.showGroupBorder &&
                         !_vm.isReviewing &&
                         !_vm.isClassOfBusinessDescription ? 'pt-30 border-t' : 'pt-0 relative',
                       _vm.isEditingQuestion === _vm.currentQuestion.id ? 'flex-col':'',
                       _vm.isReviewing ? 'flex-col': '',
                       _vm.currentQuestion.type === 'ClassOfBusiness' && _vm.questionIndex > 0 ? 'pt-50' : '',
                       _vm.isClassOfBusinessDescription && 'flex-col md:flex-row']},[(_vm.isEditingQuestion === _vm.currentQuestion.id)?_c('QuestionLabelParsed',{attrs:{"input":_vm.currentQuestion.question}}):_c('div',{staticClass:"question-wording_wrapper flex flex-row items-start pr-10 pb-4",class:[_vm.currentQuestion.type === 'ClassOfBusiness' ? 'absolute hidden' : 'w-1/2',
                         _vm.isClassOfBusinessDescription && 'w-full md:w-1/2 mb-10 md:mb-0'],style:({width: _vm.isReviewing ? '100%':''})},[_vm._t("question-wording")],2),_c('div',{staticClass:"question-control_wrapper flex flex-col md:relative",class:[_vm.currentQuestion.type === 'ClassOfBusiness' ? 'pl-0 w-full' : 'w-1/2',
                         _vm.isReviewing ? 'items-start':'items-end',
                         _vm.isClassOfBusinessDescription && 'w-full md:w-1/2'],style:({width: _vm.isReviewing ? '100%':''})},[_vm._t("question-control")],2)],1)]):[_c('div',{class:[ _vm.isReviewing ? 'flex-column items-start':'flex flex-col']},[_c('div',[_vm._t("question-wording")],2),_c('div',{class:[_vm.questionControlStyling, _vm.isReviewing ? 'items-start' : 'md:relative items-end']},[_vm._t("question-control")],2)])]],2)]),(_vm.showHint && !_vm.isReviewing && !_vm.isEditingQuestion)?_c('div',{staticClass:"relative justify-end lg:w-1/2 lg:flex"},[_c('div',{staticClass:"absolute w-2/3",staticStyle:{"z-index":"10"}},[_vm._t("hint")],2)]):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }