







import { Component, Vue } from 'vue-property-decorator'

const NAVBAR_HEIGHT = 65
const QUESTION_HEIGHT = 210
const CONTINUE_HEIGHT = 60

@Component({
  name: 'Spacer'
})
export default class Spacer extends Vue {
  get height () {
    return `${window.innerHeight - NAVBAR_HEIGHT - QUESTION_HEIGHT - CONTINUE_HEIGHT}px`
  }
}
