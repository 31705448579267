














import { Component, Mixins, Prop } from 'vue-property-decorator'
import BrizaMixin from '@/mixins'
import { Address } from '@/models/questionnaire'
import { GooglePlace } from '@/models/google-place'
import { GeometryInfo } from '@/models/address'

@Component({
  name: 'MapSearchBar'
})
export default class MapSearchBar extends Mixins(BrizaMixin) {
  @Prop() address!: Address
  @Prop() controlId!: string
  @Prop() placeholder!: string
  @Prop() onAddressChange!: (address: Address) => {}
  @Prop() setLatitudeAndLongitude!: (address: GeometryInfo) => {}

  focusHandler (event: Event) {
    this.$emit('focus', event)
  }

  get componentRestrictions () {
    return {
      country: 'us'
    }
  }

  getFullAddress () {
    const { street, city, region } = this.address
    return `${street} ${city} ${region}`.trim()
  }

  /**
   * gmapPlaceHandler function used for autocomplete component
   * returns place with address_components array that holds address info
   * address_components broken down as follows
   * [0] - street number
   * [1] - route (aka street name)
   * [2] - locality (aka city)
   * [3] - administrative_area_level_2 (aka county)
   * [4] - administrative_area_level_1 (aka state)
   * [5] - country
   * [6] - postal_code
   * @param place
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  gmapPlaceHandler (place: any) {
    if (place) {
      this.setLatitudeAndLongitude && this.setLatitudeAndLongitude({ geometry: place.geometry })
      this.onAddressChange({
        street: `${this.getAddressInfo(place, 'street_number')} ${this.getAddressInfo(place, 'route')}`,
        city: this.getAddressInfo(place, 'locality') || this.getAddressInfo(place, 'neighborhood'),
        region: this.getAddressInfo(place, 'administrative_area_level_1'),
        postalCode: this.getAddressInfo(place, 'postal_code'),
        county: this.getAddressInfo(place, 'administrative_area_level_2'),
        country: this.getAddressInfo(place, 'country'),
        secondary: ''
      })
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getAddressInfo (place: any, type: string) {
    const addressComponents = place.address_components as GooglePlace[]
    const addressField = addressComponents.find(field => field.types.includes(type)) as GooglePlace
    return addressField?.short_name || ''
  }
}
