




































































































































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { binderModule } from '@/store/store-accessor'
import Select from '@/components/controls/select/index.vue'
import NoQuestionType from './no-questions-type.vue'
import TextArea from '@/components/controls/text-area/index.vue'
import Toggle from '@/components/controls/toggle/index.vue'
import ToggleCheckbox from '@/components/controls/toggle-checkbox/index.vue'
import MultiSelect from '@/components/controls/multi-select/index.vue'
import Tiles from '@/components/tiles/index.vue'
import ProductType from '@/components/product-type/index.vue'
import ClassOfBusiness from '@/components/class-of-business/index.vue'
import AddressComponent from '@/components/address/index.vue'
import WcClassComponent from '@/components/wc-class/index.vue'
import { ValidationMessage } from '@/models/validation-messages'
import {
  AnswerMap,
  AnswerValue,
  Question,
  QuestionControlType,
  QuestionType
} from '@/models/questionnaire'
import Hint from '@/components/hint/index.vue'
import { InfoIcon, XIcon } from 'vue-feather-icons'
import TextInput from '@/components/controls/text-input/index.vue'
import BrizaMixin from '@/mixins'
import ErrorLabel from '@/components/controls/error-label/index.vue'
import QuestionLayout from '@/layouts/question-layout.vue'
import QuestionLabel from '@/components/question-component/question-label.vue'
import SingleLineControlWrapper from '@/components/controls/text-input/singleline-control-wrapper.vue'
import DateControl from '@/components/controls/text-input/date-control.vue'
import Numeric from '@/components/controls/numeric/index.vue'
import { cleanMask } from '@/helpers/utils'
import DrawerDisplayMixin from '@/mixins/drawer-display-mixin'
import BrokerageMixin from '@/mixins/brokerage-mixin'
import Drawer from '@/components/drawer/index.vue'
import MultiSelectToggle from '@/components/multi-select-toggle/index.vue'
import Checkbox from '@/components/controls/checkbox/index.vue'
import { UnansweredValue } from '@/helpers/types'

@Component({
  name: 'Question',
  components: {
    Drawer,
    QuestionLabel,
    QuestionLayout,
    ErrorLabel,
    TextInput,
    Hint,
    NoQuestionType,
    TextArea,
    Toggle,
    ToggleCheckbox,
    MultiSelect,
    Tiles,
    ProductType,
    Select,
    ClassOfBusiness,
    WcClassComponent,
    AddressComponent,
    InfoIcon,
    SingleLineControlWrapper,
    DateControl,
    Numeric,
    XIcon,
    MultiSelectToggle,
    Checkbox
  }
})
export default class QuestionComponent extends Mixins(
  BrizaMixin,
  DrawerDisplayMixin,
  BrokerageMixin
) {
  @Prop() readonly questions!: Question[];
  @Prop() readonly questionKey!: string;
  @Prop() readonly answers!: AnswerMap;
  @Prop() readonly group!: boolean;
  @Prop() readonly questionIndex: number | undefined;
  @Prop() readonly groupLength!: number;
  @Prop() readonly groupItems!: string[];
  @Prop({ default: false }) readonly isReviewing!: boolean;
  answer: AnswerValue = '';
  multi = false;
  displayDrawer = false;
  hintOverride: string[] | null = null;

  // filter-components the current question from the question array
  get currentQuestion (): Question {
    const question = this.questions.find(
      (question) => question.id === this.questionKey
    )
    if (!question) {
      throw new Error(`question ${this.questionKey} not found`)
    }

    return question
  }

  // validation messages array that holds all issues with each question
  get validationMessages (): ValidationMessage[] {
    return binderModule.validationMessages
  }

  // find the current question type
  get currentQuestionType (): string {
    this.multi = false
    switch (this.currentQuestion.type) {
      case QuestionType.Address:
        return 'AddressComponent'
      case QuestionType.SingleLine:
      case QuestionType.DateYear:
        return 'SingleLineControlWrapper'
      case QuestionType.MultiLine:
        return 'TextArea'
      case QuestionType.Select:
        if (this.currentQuestion.ui?.control === QuestionControlType.Toggle) {
          return 'Toggle'
        }
        return 'Select'
      case QuestionType.MultiSelect:
        this.multi = true
        if (this.currentQuestion.ui?.control === QuestionControlType.Toggle) {
          return 'MultiSelectToggle'
        }
        return 'Tiles'
      case QuestionType.Number:
        return 'Numeric'
      case QuestionType.Decimal:
        return 'Numeric'
      case QuestionType.Currency:
        return 'Numeric'
      case QuestionType.Date:
        return 'DateControl'
      case QuestionType.Boolean:
        return this.currentQuestion.ui?.control === QuestionControlType.Checkbox
          ? 'Checkbox'
          : 'ToggleCheckbox'
      case QuestionType.ClassOfBusiness:
        return 'ClassOfBusiness'
      case QuestionType.WcClassCode:
        return 'WcClassComponent'
      case QuestionType.ProductType:
        return 'ProductType'
      case QuestionType.Void:
        return ''
      default:
        throw new Error(
          `component for question ${this.currentQuestion.id} does not exist`
        )
    }
  }

  // Getting answer to current question from answers prop
  get currentAnswer () {
    const answer = this.answers[this.questionKey]

    // Unanswered questions have value "null" (relevant to conditional visibility rules)
    if (answer === undefined) {
      return UnansweredValue
    }

    return answer
  }

  get questionHint (): string[] {
    return this.hintOverride ?? this.currentQuestion.ui?.hint ?? []
  }

  get activeControl (): string {
    return binderModule.activeControl
  }

  get displayHint (): boolean {
    return this.activeControl === this.currentQuestion.id
  }

  get firstIllustration (): boolean {
    return (
      this.$store.state.BinderModule.activeControl === 'BusinessName' &&
      this.currentQuestion.id === 'BusinessName'
    )
  }

  get secondIllustration (): boolean {
    return (
      this.$store.state.BinderModule.activeControl === 'NumberOfLocations' &&
      this.currentQuestion.id === 'NumberOfLocations'
    )
  }

  get thirdIllustration () {
    return (
      this.group &&
      this.questionIndex === 0 &&
      binderModule.activeControl.indexOf('ClassOfBusiness') !== -1
    )
  }

  get fourthIllustration (): boolean {
    return this.questionKey === 'RequestedTypesOfInsurance'
  }

  get error (): string {
    return (
      binderModule.validationMessages.find(
        (error) => error.field === this.currentQuestion.id
      )?.message || ''
    )
  }

  /**
   * Custom error handling per question type predicate.
   */
  get customErrorHandling (): boolean {
    if ([QuestionType.ClassOfBusiness].includes(this.currentQuestion.type)) {
      return true
    }
    return false
  }

  get numberOfLocations (): number | null {
    return binderModule.answers.NumberOfLocations
      ? (binderModule.answers.NumberOfLocations as number)
      : null
  }

  focusHandler (event: Event) {
    let target = event.target as HTMLElement | null
    let questionId = null
    while (target && !questionId) {
      questionId = target.dataset.qid || target.id
      if (!questionId) {
        target = target.parentElement
      }
    }
    if (questionId) {
      binderModule.setActiveControl(questionId)
    }
  }

  toggleDisplayHintComponent () {
    this.displayDrawer = !this.displayDrawer
  }

  overrideHint (hint: string[]) {
    this.hintOverride = hint
  }

  updateAnswer (answer: AnswerValue) {
    this.isValid(answer, this.currentQuestion)
    if (
      this.currentQuestion.type === QuestionType.DateYear &&
      typeof answer === 'string'
    ) {
      this.sendAnswer(answer.split('-', 1)[0], this.currentQuestion)
    } else {
      this.sendAnswer(answer, this.currentQuestion)
    }
  }

  blurHandler (event: Event) {
    const target = event.target as HTMLInputElement
    this.isValid(
      cleanMask(target.value, this.currentQuestion.ui.mask),
      this.currentQuestion
    )
  }

  changeHandler (value: AnswerValue) {
    this.updateAnswer(value)
  }

  updateCOB (cob: string) {
    if (this.numberOfLocations && this.numberOfLocations > 1) {
      for (let x = 2; x <= this.numberOfLocations; x++) {
        const locationQuestion = binderModule.questions.find(
          (question) => question.id === `Location${x}ClassOfBusiness`
        )
        if (locationQuestion) {
          this.sendAnswer(cob, locationQuestion)
        }
      }
    }
  }
}
