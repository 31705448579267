
















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'Checkbox'
})
export default class Checkbox extends Vue {
  @Prop() currentAnswer!: boolean
  @Prop() controlId!: string
  answer = false

  mounted () {
    this.answer = this.currentAnswer ?? false
  }
}
