import { render, staticRenderFns } from "./question-group-label.vue?vue&type=template&id=7c5c9276&"
import script from "./question-group-label.vue?vue&type=script&lang=ts&"
export * from "./question-group-label.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports