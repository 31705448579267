
















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  name: 'ApplicationLayout'
})
export default class ApplicationLayout extends Vue {
  @Prop({ default: false }) hideMenu!: string;
}
