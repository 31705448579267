



















































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Question, QuestionType } from '@/models/questionnaire'
import { InfoIcon } from 'vue-feather-icons'
import { renderMarkdown } from '@/helpers/markdown'
import QuestionLabelParsed from './question-label-parsed.vue'
import { ROUTES } from '@/router/routes'

@Component({
  name: 'QuestionLabel',
  components: {
    InfoIcon,
    QuestionLabelParsed
  }
})
export default class QuestionLabel extends Vue {
  @Prop() question!: Question
  @Prop() group!: boolean
  @Prop() type!: string
  @Prop({ default: false }) isReviewing!: boolean
  @Prop({ default: false }) isRequiringUpdate!: boolean

  markDown () {
    const questionWording = this.question.shortQuestion ?? this.question.questionWithinGroup ?? this.question.question
    return renderMarkdown(this.hideOptional
      ? questionWording
      : `${questionWording} <span class="">(optional)</span>`)
  }

  get hideOptional (): boolean {
    return this.question.required || [QuestionType.Boolean, QuestionType.Void].includes(this.question.type) ||
      (this.question.type === QuestionType.Select && this.question.ui?.control === 'toggle' &&
        this.question.default !== undefined)
  }

  get questionHint (): string[] {
    return this.question.ui?.hint || []
  }

  get isPreApp (): boolean {
    return this.$router?.currentRoute.name === ROUTES.PRE_APPLICATION
  }

  get isApplicationQuestionTitle (): boolean {
    return (this.$router?.currentRoute.name === ROUTES.APPLICATION || this.isReviewing) && !this.group
  }

  get isQuestionDescription (): boolean {
    return this.question.type === QuestionType.Void
  }

  get statusClass () {
    return this.isRequiringUpdate && this.group ? 'requires-uptate' : ''
  }

  get questionType () {
    return QuestionType
  }
}
