



























import { Component, Prop, Vue } from 'vue-property-decorator'
import { QuestionOption } from '@/models/questionnaire'

@Component({
  name: 'Tiles'
})
export default class Tiles extends Vue {
  @Prop({ default: false }) readonly multi!: boolean;
  @Prop() options!: QuestionOption[];
  @Prop() controlId!: string;
  @Prop() currentAnswer!: string[]
  answer: string[] = [];

  mounted () {
    this.answer = this.currentAnswer ?? []
  }

  /**
   * method to update the answers object with the selected keys only
   * if key is found, it will be deselected/removed
   * @param key
   */
  updateSelection (key: string) {
    const keyIndex = this.answer.indexOf(key)
    if (this.multi) {
      keyIndex !== -1
        ? this.answer.splice(keyIndex, 1)
        : this.answer.push(key)
    } else {
      this.answer = [key]
    }
    this.$emit('update-answer', this.answer)
  }
}
