





























import { Component, Prop, PropSync, Mixins } from 'vue-property-decorator'
import BrizaMixin from '@/mixins'
import { Question, QuestionOption } from '@/models/questionnaire'

@Component({
  name: 'MultiSelect'
})
export default class MultiSelect extends Mixins(BrizaMixin) {
  @PropSync('value', { type: String }) content!: string
  @Prop() question!: Question
  @Prop() answer!: number
  @Prop() options!: QuestionOption[]
  @Prop({ default: false }) isReviewing!: boolean

  focusHandler (event: Event) {
    this.$emit('focus-handler', event)
  }
}
