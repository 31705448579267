




































































































import { defineComponent, onMounted, ref, computed } from '@vue/composition-api'
import TextInput from '@/components/controls/text-input/index.vue'
import { Link } from '@/models/link'
import { binderModule } from '@/store/store-accessor'
import SaveForLaterConfirmation from '@/components/save-for-later/confirmation.vue'
import LoadingIcon from '@/components/loading-icon/index.vue'
import useBrokerage from '@/use/useBrokerage'
import { validateEmail } from '@/helpers/validations'
import CalloutBox from '@/components/callout-box/index.vue'
import { InfoIcon } from 'vue-feather-icons'

export default defineComponent({
  name: 'SaveForLaterForm',
  components: {
    TextInput,
    SaveForLaterConfirmation,
    LoadingIcon,
    CalloutBox,
    InfoIcon
  },
  props: {
    progress: {
      type: String,
      required: true
    }
  },
  emits: ['close-drawer'],
  setup (props) {
    const { brokerageEmail, brokeragePhone, brokerageName } = useBrokerage()
    const email = ref('')
    const isLoading = ref(false)

    const showSuccessfullMessage = ref(false)
    const showErrorMessage = ref(false)
    const isValidEmail = computed(() => {
      return validateEmail(email.value)
    })

    onMounted(() => {
      email.value = binderModule.answers.LinkEmail as string || ''
    })

    const resetForm = () => {
      showSuccessfullMessage.value = false
      isLoading.value = false
    }

    const saveApplication = async () => {
      showErrorMessage.value = false
      isLoading.value = true
      const data: Link = {
        email: email.value,
        meta: {
          progress: props.progress
        }
      }
      try {
        await binderModule.saveForLater(data)
      } catch (error: any) {
        isLoading.value = false
        showErrorMessage.value = true
        return
      }
      showSuccessfullMessage.value = true
      // revert back to form, in case user would like to change email address
      setTimeout(() => {
        resetForm()
      }, 5000)
    }

    return {
      brokeragePhone,
      brokerageName,
      brokerageEmail,
      isValidEmail,
      saveApplication,
      isLoading,
      email,
      showSuccessfullMessage,
      showErrorMessage
    }
  }
})
