






















import { defineComponent } from '@vue/composition-api'
import SaveForLaterForm from '@/components/save-for-later/index.vue'
import { XIcon } from 'vue-feather-icons'
import Drawer from '@/components/drawer/index.vue'

export default defineComponent({
  name: 'DrawerSaveForLater',
  components: { SaveForLaterForm, XIcon, Drawer },
  props: {
    progress: {
      type: String,
      required: true
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close-drawer']
})
