



































import {
  defineComponent,
  computed,
  watch,
  onBeforeUnmount
} from '@vue/composition-api'
import { binderModule } from '@/store/store-accessor'

export default defineComponent({
  name: 'Drawer',
  props: {
    showCloseButton: { type: Boolean, default: true },
    title: { type: String, default: '' },
    position: { type: String, default: 'right' }, // 'bottom','right'
    isOpen: { type: Boolean, default: true },
    showHeader: { type: Boolean, default: true },
    customCloseAction: { type: Boolean, default: false }
  },
  emits: ['close'],
  setup (props, { emit }) {
    const closeDrawer = () => {
      if (!props.customCloseAction) binderModule.toggleDrawer()
      emit('close')
    }

    const positionClass = computed(() => {
      return 'drawer--' + props.position
    })

    watch(() => props.isOpen, (val) => {
      if (!val && binderModule.toggleReviewOverlay) return
      binderModule.setDrawerState(val)
    })

    onBeforeUnmount(() => {
      if (props.isOpen) {
        binderModule.setDrawerState(false)
      }
    })

    return { closeDrawer, positionClass }
  }

})
