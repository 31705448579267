










































import { Component, Prop, Vue } from 'vue-property-decorator'
import { QuestionOption } from '@/models/questionnaire'

@Component({
  name: 'MultiSelectToggle'
})
export default class MultiSelectToggle extends Vue {
  @Prop() options!: QuestionOption[];
  @Prop() controlId!: string;
  @Prop() currentAnswer!: string[]
  @Prop() error!: string
  answer: string[] = [];
  @Prop({ default: false }) isReviewing!: boolean

  mounted () {
    this.answer = this.currentAnswer ?? []
    this.$emit('update-answer', this.answer)
  }

  updateSelection (key: string) {
    this.isOptionChecked(key) ? this.uncheck(key) : this.check(key)
  }

  isOptionChecked (key: string): boolean {
    return this.answer.includes(key)
  }

  uncheck (key: string) {
    this.answer = this.answer.filter(opt => opt !== key)
    this.$emit('update-answer', this.answer)
  }

  check (key: string) {
    this.answer.push(key)
    this.$emit('update-answer', this.answer)
  }
}
