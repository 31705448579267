


















import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import BrizaMixin from '@/mixins'
import isEqual from 'lodash/isEqual'
import { WcClass } from '@/models/wc-class'
import { binderModule, wcModule } from '@/store/store-accessor'
import { QuestionOption, AnswerValue, Address } from '@/models/questionnaire'
import Select, { defaultFilterMatchPredicate } from '@/components/controls/select/index.vue'
import {
  findWcClass,
  determineLocationQuestionIdForWcQuestion,
  sortedWcClassOptions
} from '@/helpers/wc-class'
import { UnansweredValue } from '@/helpers/types'

@Component({
  name: 'WcClass',
  components: {
    Select
  }
})
export default class WcClassComponent extends Mixins(BrizaMixin) {
  @Prop() currentAnswer!: AnswerValue
  @Prop() error!: string
  @Prop({ default: false }) isReviewing!: boolean

  currentLocation = {}
  locationQuestionId = ''
  wcClasses: WcClass[] | undefined
  wcClassOptions : QuestionOption[] = []

  /**
   * Match option by NCCI Class code or by label (case insensitive)
   * Note:
   * - Min 4 charectesr required to engage the search
   * - NCCI sub class code format: XXXX-Y (Y = any number)
   */
  readonly wcfilterMatchPredicate = (option: QuestionOption, input: string) =>
    input.length < 4
      ? false
      : input.match(/^\d{4}/)
        ? `${option.key}`.startsWith(`${input}-`)
        : defaultFilterMatchPredicate(option, input)

  beforeMount () {
    this.locationQuestionId = determineLocationQuestionIdForWcQuestion(this.question.id)
    this.fetchWcClasses()
  }

  beforeUnmount () {
    this.sendAnswer(UnansweredValue)
  }

  get answers () {
    return binderModule.answers
  }

  @Watch('answers')
  async watchForLocationChanges () {
    const latestLocation = this.answers[this.locationQuestionId] as Address

    if (!isEqual(this.currentLocation, latestLocation)) {
      this.sendAnswer(UnansweredValue)
    }
  }

  @Watch('answers')
  async fetchWcClasses () {
    const locationAnswer = this.answers[this.locationQuestionId] as Address
    this.currentLocation = locationAnswer

    if (locationAnswer?.region) {
      this.wcClasses = await wcModule.intializeWcClass(locationAnswer.region)
      this.wcClassOptions = sortedWcClassOptions(this.wcClasses || [])
    }
  }

  updateClassCode (code: string) {
    if (code && this.wcClasses) {
      const wcClass = findWcClass(code, this.wcClasses)

      if (wcClass === undefined) {
        throw new Error(`could not find wcClass for code: ${code}`)
      }

      this.sendAnswer({
        code: wcClass.code,
        industry: wcClass.industry
      })
    } else {
      this.sendAnswer(null)
    }
  }
}
