import { Component, Vue, Watch } from 'vue-property-decorator'
import VueScrollTo from 'vue-scrollto'
import { clamp } from '@/helpers/utils'
import { QuestionOption } from '@/models/questionnaire'
import { InputState } from '@/models/select'

@Component
export default class SelectMixin extends Vue {
  open = false
  inputValue = ''
  selectionIndex = -1
  answer: string | number | boolean = ''
  selection = ''
  selectState = InputState.Empty

  @Watch('disabled')
  onDisabledChanged (val: boolean) {
    if (val) this.open = false
  }

  /**
   * Get icon component for the given state.
   */
  get stateIcon () {
    return this.selectState === InputState.Selected
      ? 'x-icon'
      : this.open
        ? 'chevron-up-icon'
        : 'chevron-down-icon'
  }

  scrollIntoView () {
    this.$nextTick(async () => {
      VueScrollTo.scrollTo(this.$refs.openOptions as HTMLElement, 500, {
        force: false,
        offset: -200
      })
    })
  }

  /**
   * Move section in the open select.
   * @param direction Direction of the move.
   */
  upDownKeyHandler (direction: -1 | 1, options: QuestionOption[], $event: KeyboardEvent) {
    $event.stopPropagation()
    this.selectionIndex = clamp(
      this.selectionIndex + direction,
      0,
      options.length - 1
    )

    // Scroll the container to the selection.
    const selected = (this.$refs.openOptions as HTMLElement)
      .querySelector('.bg-primaryAccent') as HTMLElement
    if (selected) {
      VueScrollTo.scrollTo(selected, 100, {
        container: (this.$refs.openOptions as HTMLElement),
        offset: -selected.clientHeight
      })
    }
  }

  /**
   * selecting the first filtered value from the options list
   */
  enterKeyHandler (options: QuestionOption[]) {
    if (options.length > 0 && this.open) {
      let selectedKey
      if (this.selectionIndex !== -1) {
        selectedKey = options[this.selectionIndex]
        this.open = false
        this.selectOption(selectedKey)
        this.selectionIndex = -1
      }
    }
  }

  selectOption (option?: QuestionOption) {
    if (option) {
      this.selectState = InputState.Selected
      this.selection = option.label
      this.answer = option.key
      this.inputValue = this.selection
      this.$emit('change', option.key)
      this.open = false
      document.removeEventListener('click', this.handleClickOutside)
    }
  }

  handleClickOutside (event: Event) {
    if (!this.$el.contains(event.target as HTMLInputElement)) {
      this.open = false
    }
  }

  // @ts-ignore
  gotFocus (event: Event, cb?: Function) {
    this.selectionIndex = 0
    this.scrollIntoView()
    this.$emit('focus', event)
    if (cb) cb()
    if (!this.open) this.open = true
  }

  blurHandler (options: QuestionOption[], cb?: Function) {
    const selection = options[this.selectionIndex]
    if (selection) {
      this.selectOption(selection)
    } else {
      if (cb) cb()
      this.open = false
    }
  }

  /**
   * Update selection index.
   * @param index Seleciton index.
   */
  onChangeSelection (index: number) {
    this.selectionIndex = index
  }

  // toggle selelction list visibility
  toggleOpen (disabled: boolean) {
    if (!disabled) this.open = !this.open
  }
}
