












import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'CalloutBox',
  props: {
    type: {
      type: String,
      default: '' // '' | error
    },
    isSticky: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const customStyle = computed(() => {
      switch (props.type) {
        case 'error':
          return {
            borderColor: '#E44258',
            backgroundColor: '#FCECEE',
            width: '100%',
            color: '#E44258',
            fontWeight: '600'
          }
        default:
          return {}
      }
    })

    return { customStyle }
  }
})
