






























































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { AnswerMap, Question, QuestionType } from '@/models/questionnaire'
import QuestionLabelParsed from '@/components/question-component/question-label-parsed.vue'
import { binderModule } from '@/store/store-accessor'

@Component({
  name: 'QuestionLayout',
  components: {
    QuestionLabelParsed
  }
})
export default class QuestionLayout extends Vue {
  @Prop() readonly currentQuestion!: Question
  @Prop() readonly group!: boolean
  @Prop() readonly questionIndex!: number
  @Prop() readonly groupItems!: string[]
  @Prop() readonly questions!: Question[]
  @Prop() readonly answers!: AnswerMap
  @Prop({ default: false }) isReviewing!: boolean
  @Prop({ default: true }) showHint!: boolean

  get showGroupBorder (): boolean {
    return this.questionIndex > 0
  }

  get isEditingQuestion (): string {
    return binderModule.toggleEditQuestion
  }

  get isClassOfBusinessDescription (): boolean {
    return this.currentQuestion.id.includes('BusinessDescription')
  }

  get questionControlStyling () {
    if (this.$route?.meta?.title === 'Pre-Application') {
      if (this.currentQuestion.id === 'NumberOfLocations') {
        return 'w-full lg:w-2/3 justify-start'
      } else {
        return 'w-full justify-start'
      }
    } else {
      if (this.currentQuestion.type === QuestionType.MultiSelect) {
        return 'w-full justify-start'
      }
    }
    if (this.isReviewing) {
      return ''
    }
    return 'w-full md:w-1/2 justify-start'
  }
}
