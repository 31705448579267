






import { Component, Vue } from 'vue-property-decorator'
@Component({
  name: 'NoQuestionType'
})
export default class NoQuestionType extends Vue {}
