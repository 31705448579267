



















































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Question, QuestionType } from '@/models/questionnaire'
import { AlertTriangleIcon, CheckIcon } from 'vue-feather-icons'

type NumericAnswer = number | string | null

const hasPrecision = (type: QuestionType): boolean =>
  [QuestionType.Decimal, QuestionType.Currency].includes(type)

const serializeAnswer = (answer: number | null, type: QuestionType): NumericAnswer => {
  if (answer === null) {
    return answer
  }

  if (type === QuestionType.Currency) {
    if (Number.isInteger(answer)) {
      // e.g. 3000 -> "3000"
      return answer.toFixed(0)
    }
    // e.g. 25000.10 -> "25000.1000"
    return answer.toFixed(4)
  }

  if (type === QuestionType.Decimal) {
    // e.g. 300.324 -> "300.324"
    return answer.toPrecision()
  }

  // otherwise integer numbers
  return answer
}

@Component({
  name: 'Numeric',
  components: {
    AlertTriangleIcon,
    CheckIcon
  }
})
export default class Numeric extends Vue {
  @Prop() type!: string
  @Prop() question!: Question
  @Prop() inputHint!: string
  @Prop() error!: string
  @Prop() currentAnswer!: NumericAnswer
  answer: number | null = null
  @Prop({ default: false }) isReviewing!: boolean

  mounted () {
    if (hasPrecision(this.question.type) && typeof this.currentAnswer === 'string') {
      this.answer = parseFloat(this.currentAnswer)
    } else {
      if (typeof this.currentAnswer === 'string') {
        throw new Error('answer value should not be string for Number question type')
      }
      this.answer = this.currentAnswer ?? null
    }
  }

  get currency (): string | null {
    return this.question.currency ?? null
  }

  blurHandler () {
    this.$emit('update-answer', serializeAnswer(this.answer, this.question.type))
  }

  changeHandler () {
    this.$emit('update-answer', serializeAnswer(this.answer, this.question.type))
  }
}
