











import BrizaMixin from '@/mixins'
import { Component, Mixins, Prop } from 'vue-property-decorator'

@Component({
  name: 'ManualAddressToggle'
})
export default class ManualAddressToggle extends Mixins(BrizaMixin) {
  @Prop() isManual!: boolean
  @Prop() handleManualClick!: () => {}
}
