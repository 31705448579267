



















































import { Component, Prop, PropSync, Mixins } from 'vue-property-decorator'
import BrizaMixin from '@/mixins'
import ErrorLabel from '@/components/controls/error-label/index.vue'
import { AnswerValue, Question, QuestionOption } from '@/models/questionnaire'

@Component({
  name: 'Toggle',
  components: { ErrorLabel }
})
export default class Toggle extends Mixins(BrizaMixin) {
  @PropSync('value', { type: String }) content!: string
  @Prop() question!: Question
  @Prop() controlId!: string
  @Prop() currentAnswer!: string | number
  @Prop() options!: QuestionOption[]
  @Prop() answer!: AnswerValue
  @Prop() error!: string
  @Prop({ default: false }) isReviewing!: boolean

  get isReviewingAnswer () {
    return this.options.find(option => option.key === this.currentAnswer)?.label
  }

  keyHandler (event: KeyboardEvent) {
    if (event.keyCode === 13) {
      (event.target as HTMLElement).click()
    }
  }
}
