import { WcClass } from '@/models/wc-class'
import { QuestionOption } from '@/models/questionnaire'

export function findWcClass (code: string, wcClasses: WcClass[]): WcClass | undefined {
  return wcClasses.filter(wcClass => wcClass.children)
    .flatMap(wcClass => wcClass.children)
    .find(leaf => leaf?.code === code)
}

export function determineLocationQuestionIdForWcQuestion (questionId: string): string {
  const match = questionId.match(/^Location(\d+)Wc(\d+)Code$/)

  if (!match) {
    throw new Error(`cannot derive location questionId by question ${questionId}`)
  }

  return `Location${match[1]}Address`
}

export function sortedWcClassOptions (wcClasses: WcClass[]): QuestionOption[] {
  return wcClasses?.filter(wcClass => wcClass.children)
    .flatMap(wcClass => wcClass.children)
    .filter((wcClass): wcClass is WcClass => wcClass !== undefined)
    .map(({ title, code }) => ({ label: title, key: code }))
    .sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0)) ?? []
}
