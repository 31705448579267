

































import moment from 'moment'
import { Component, Vue, Prop } from 'vue-property-decorator'
import DatePick from 'vue-date-pick'
import 'vue-date-pick/dist/vueDatePick.css'
import { CalendarIcon } from 'vue-feather-icons'
import { CalendarControl } from '@/models/calendar-control'

@Component({
  name: 'DateControl',
  components: {
    DatePick,
    CalendarIcon
  }
})

export default class DateControl extends Vue {
  @Prop() controlId!: string
  @Prop() error!: string
  @Prop() inputHint: string | undefined
  @Prop() value!: string
  @Prop() currentAnswer!: string
  @Prop({ default: false }) isReviewing!: boolean
  answer = ''

  mounted () {
    if (this.currentAnswer) {
      // Normalize input valud for the calendar component
      // size 4 indicates the DateYear quesiton type answers (yyyy)
      this.answer = this.currentAnswer.length === 4
        ? `${this.currentAnswer}-01-01`
        : moment(this.currentAnswer as string)
          .format('YYYY-MM-DD')
          .toString()
    } else {
      this.answer = ''
    }
  }

  get isReviewingAnswer (): string | null {
    if (this.answer) {
      return moment(this.answer as string).format('MM/DD/YYYY').toString()
    }
    return null
  }

  setFocus () {
    const controlRefId = Object.keys(this.$refs)
    if (controlRefId.length > 0) {
      const control = (this.$refs[controlRefId[0] as string] as unknown as CalendarControl)
      control.opened = true
    }
  }

  updateAnswer (val: string) {
    const valid = !val || /^\d{4,4}-\d{2,2}-\d{2,2}$/.test(val)
    this.$emit('update-answer', valid ? (val || null) : null)
  }
}
