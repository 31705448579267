
import { Component, Prop, Vue } from 'vue-property-decorator'
import { binderModule } from '@/store/store-accessor'
import EventBus from '@/event-bus'
import { AnswerValue, AnswerMap, Question } from '@/models/questionnaire'
import { scrollToSection, scrollToQuestion } from '@/helpers/scroll'
import { Events } from '@/models/events'

@Component
export default class ApplicationMixin extends Vue {
  @Prop()
  id!: string

  mounted () {
    // Using the EVENT BUS to communicate between component and ultimately
    // setting the answer in the global state
    EventBus.$on(Events.UPDATE_ANSWER, (questionKey: string, answer: AnswerValue) => {
      const data = {
        [questionKey]: answer
      }
      binderModule.addAnswer(data)
    })
  }

  // Current visible section on screen
  get visibleSections (): string[] {
    return binderModule.visibleSections
  }

  get applicationComplete (): boolean {
    return this.visibleSections.length === binderModule.totalSections
  }

  // Application Questions from the global state
  get questions (): Question[] {
    if (!binderModule.application) {
      return [] // Still loading
    }
    return binderModule.application.questionnaire.questions
  }

  // Answers object from the global state
  get answers (): AnswerMap {
    return binderModule.answers
  }

  get isFormValid (): boolean {
    return binderModule.validationMessages.length <= 0
  }

  // current step in the pre-app, mostly used for the step counter control
  get currentStep (): number {
    return binderModule.visibleSections.length
  }

  get currentStepId (): string {
    return binderModule.visibleSections[binderModule.visibleSections.length - 1]
  }

  async focusField (questionId: string) {
    await scrollToQuestion(questionId)
    const input = document.querySelector(`[data-qid=${questionId}]`)
    if (input) {
      (input as HTMLInputElement).focus()
    }
  }

  async showNextSection () {
    await binderModule.nextSection()
    scrollToSection(this.currentStepId)
  }
}
