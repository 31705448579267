



































































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { Question, QuestionOption } from '@/models/questionnaire'
import TextInput from '@/components/controls/text-input/index.vue'
import { ChevronDownIcon, ChevronUpIcon, XIcon } from 'vue-feather-icons'
import BrizaMixin from '@/mixins'
import SelectMixin from '@/mixins/select-mixin'
import { InputState } from '@/models/select'

/**
 * Option filter match based on the input value predicate.
 */
export type FilterMatchPredicate = (option: QuestionOption, input: string) => boolean

/**
 * Match option by label (case insensitive)
 */
export const defaultFilterMatchPredicate = (option: QuestionOption, input: string) =>
  option.label.toLowerCase().includes(input.toLowerCase())

@Component({
  name: 'Select',
  components: {
    TextInput,
    ChevronDownIcon,
    ChevronUpIcon,
    XIcon
  }
})
export default class Select extends Mixins(BrizaMixin, SelectMixin) {
  @Prop() label!: string
  @Prop() currentAnswer!: string | number
  @Prop() options!: QuestionOption[]
  @Prop() controlId!: string
  @Prop() question!: Question
  @Prop() error!: string
  @Prop() placeholder!: string
  @Prop({ default: 'No results, please try different keyword.' }) noResults!: string
  @Prop({ default: () => defaultFilterMatchPredicate }) filterMatchPredicate!: FilterMatchPredicate
  @Prop() innerStyle!: Record<string, unknown>
  @Prop({ default: false }) isReviewing!: boolean

  @Watch('currentAnswer')
  onCurrentAnswerChanged (val: string | number) {
    const selectedOption = this.options.find((option) => option.key === val)

    if (selectedOption) {
      this.selection = selectedOption.label
      this.inputValue = selectedOption.label
    } else {
      this.selection = ''
      this.inputValue = ''
    }
  }

  get isReviewingAnswer () {
    return this.selection
  }

  mounted () {
    if (this.currentAnswer === null && this.question && this.question.default !== undefined) {
      this.selectOption(this.options.find((option) => option.key === this.question.default))
    } else {
      this.selection = this.currentAnswer
        ? this.options.filter((option) => option.key === this.currentAnswer)[0].label
        : ''
      this.inputValue = this.selection
      this.answer = this.currentAnswer ? (this.currentAnswer as string) : ''
    }
  }

  destroyed () {
    document.removeEventListener('click', this.handleClickOutside)
  }

  get filteredOptions (): QuestionOption[] {
    return this.selection.length > 0 && this.options
      ? this.options.filter((opt) => this.filterMatchPredicate(opt, this.selection))
      : (this.options as QuestionOption[])
  }

  updateOpen () {
    if (this.selectState === InputState.Selected) {
      this.inputValue = ''
      this.selectionIndex = -1
      this.answer = ''
      this.selection = ''
      this.selectState = InputState.Empty
      this.$emit('change', null)
    } else {
      this.open = !this.open
      if (this.open) {
        this.resetSearch()
        document.addEventListener('click', this.handleClickOutside)
      } else {
        this.selectOriginalValue()
        document.removeEventListener('click', this.handleClickOutside)
      }
      this.scrollIntoView()
    }
  }

  resetSearch () {
    this.selection = ''
    this.selectionIndex = this.options.findIndex((opt) => opt.key === this.currentAnswer)
    if (this.selectionIndex >= 0) {
      this.inputValue = this.options[this.selectionIndex].label
    }
  }

  inputHandler (value: string) {
    this.open = true
    this.selectionIndex = 0
    this.selection = value
  }

  handleClickOutside (event: Event) {
    if (!this.$el.contains(event.target as HTMLInputElement)) {
      this.selectOriginalValue()
      this.open = false
    }
  }

  selectOriginalValue () {
    const originalValue = this.options.filter((opt) => opt.key === this.currentAnswer)
    this.selectOption(originalValue[0])
  }

  changeHandler (event: Event) {
    const value = (event.target as HTMLSelectElement).value
    if (this.question && this.question.options && typeof this.question.options[0].key === 'number') {
      this.answer = parseInt(value)
      this.$emit('change', parseInt(value))
      return
    }
    this.answer = value
    this.$emit('change', value)
  }
}
