


























































import { Component, Vue, Prop } from 'vue-property-decorator'
@Component({
  name: 'SideNavigationLayout'
})
export default class SideNavigationLayout extends Vue {
  @Prop({ required: false, default: false }) isApplication !: boolean
}
