



















































































































































































































































































import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import { Question, Address } from '@/models/questionnaire'
import { cobModule, binderModule } from '@/store/store-accessor'
import { COB } from '@/models/cob'
import ErrorLabel from '@/components/controls/error-label/index.vue'
import {
  XIcon,
  MapPinIcon,
  RotateCcwIcon,
  SearchIcon,
  ChevronLeftIcon,
  CheckIcon
} from 'vue-feather-icons'
import { Document } from 'flexsearch'
import VueScrollTo from 'vue-scrollto'
import BusinessClassCard from './BusinessClassCard.vue'
import { gmapApi } from 'vue2-google-maps'
@Component({
  name: 'ClassOfBusiness',
  components: {
    ErrorLabel,
    ChevronLeftIcon,
    XIcon,
    RotateCcwIcon,
    MapPinIcon,
    SearchIcon,
    CheckIcon,
    BusinessClassCard
  }
})
export default class ClassOfBusiness extends Vue {
  @Prop() currentAnswer!: string;
  @Prop() question!: Question;
  @Prop() error!: string;
  @Prop() numberOfLocations!: number;
  numberOfElementsResult = 5;

  // suggestions
  suggestionInputValue = '';
  suggestionTerms = { name: '', address: {} };
  suggestionOptions: COB[] = [];
  suggestionsOpen = true;
  suggestionTypes = {
    history: 'RotateCcwIcon',
    location: 'MapPinIcon'
  }

  googlePlacesService: any = null;

  // primary options
  primaryInputValue = '';
  majorOptions: COB[] = [];
  majorOptionsOpen = false;
  searchIndex?: Document<COB>;
  majorSelected: COB | null = null;

  // secondary options
  minorOptions: COB[] = [];

  currentCobsProductTypeSlug?: string = '';
  async getCobsByProductSlug () {
    const newProductTypeSlug = Array.isArray(
      this.answers.RequestedTypesOfInsurance
    )
      ? this.answers.RequestedTypesOfInsurance[0]
      : this.answers.RequestedTypesOfInsurance

    if (this.currentCobsProductTypeSlug === newProductTypeSlug) return
    this.currentCobsProductTypeSlug = newProductTypeSlug as string

    await cobModule.initializeCOB(this.currentCobsProductTypeSlug)
    this.majorOptions = this.cobs ?? []
    this.searchIndex = this.createSearchIndex(this.cobs)
    this.dismissResults()
  }

  private getFullAddress (address: Address): string {
    if (!address || !address.street || !address.city || !address.region) return ''
    const { street, city, region } = address
    return `${street} ${city} ${region}`.trim()
  }

  private getCorrespondingAddress (): Address {
    const numberOfAddress = this.question.id.match(/\d+/)
    return this.answers[`Location${numberOfAddress ? numberOfAddress[0] : 1}Address`] as Address
  }

  private nameOrAddressChanged () {
    return this.suggestionTerms.name !== this.answers.BusinessName ||
    this.getFullAddress(this.suggestionTerms.address as Address) !==
    this.getFullAddress(this.getCorrespondingAddress())
  }

  private getHistorySuggestion () {
    for (let index = 1; index < 6; index++) {
      const cobCode = this.answers[`Location${index}ClassOfBusiness`]

      let cob:COB | undefined

      if (typeof cobCode === 'string') {
        const suggestedOption = this.businessClasses.filter((option) =>
          option.code === cobCode || option.code.includes(`${cobCode.split('-')[0]}-${cobCode.split('-')[1]}`))
        cob = suggestedOption[0]
      }
      if (cob) {
        const currentSuggestions = this.suggestionOptions.filter((option) => option.code !== cob?.code)
        cob = { ...cob, type: 'history' }
        this.suggestionOptions = [cob, ...currentSuggestions]
      }
    }
  }

  private getSuggestions () {
    this.resetDefaultState()
    this.suggestionTerms.name = this.answers.BusinessName as string
    this.suggestionTerms.address = this.getCorrespondingAddress()

    if (this.suggestionTerms.address) {
      const addressTerm = this.getFullAddress(this.suggestionTerms.address as Address)
      const query = `${addressTerm} ${binderModule.answers.BusinessName || ''}`
      if (this.googlePlacesService) {
        this.googlePlacesService.findPlaceFromQuery({ query, fields: ['types', 'name'] },
          (results: any, status: any) => {
            const typesBlacklist = [
              'point_of_interest',
              'premise',
              'street_address'
            ]
            if (status === 'OK' && !typesBlacklist.includes(results[0].types[0])) {
              const type = results && results[0] && results[0].types[0].replaceAll('_', ' ')
              this.suggestionInputValue = `${
                type || ''
              } ${binderModule.answers.BusinessName || ''}`
              this.searchHandler(this.suggestionInputValue, true)
            }
          })
      }
    }
  }

  @Watch('answers')
  async onAnswersChanged () {
    this.getCobsByProductSlug()
    if (this.nameOrAddressChanged()) {
      this.getSuggestions()
    }
    this.getHistorySuggestion()
  }

  @Watch('minorOptions')
  onMinorOptionsChanged (val: COB[]) {
    if (val.length === 1) {
      const firstMinorOption = this.minorOptions[0].ancestorTitles ? this.minorOptions[0].ancestorTitles[0] : ''
      this.minorOptions = [...val, {
        code: this.businessClasses.find((option) => option.title === firstMinorOption)?.code || '000000',
        title: 'Other'
      }]
    }
  }

  get answers () {
    return binderModule.answers
  }

  get businessClasses (): COB[] {
    return cobModule.cobs || []
  }

  get cobs (): COB[] {
    // for each minor cob that have appetite, create a major cob to be searchable
    const searchableCobs: COB[] = this.businessClasses.map((cob: COB) => {
      const parentMajorCob = cob.ancestorTitles && cob.ancestorTitles[1] ? cob.ancestorTitles[1] : ''
      const isMinorCob = cob.code.split('-').length === 3
      const title = (isMinorCob ? (parentMajorCob) : cob.title) || ''
      const childrenTitles = this.businessClasses?.map((businessClass) =>
        businessClass?.ancestorTitles?.includes(title) && businessClass.code.split('-')[0] === cob.code.split('-')[0]
          ? businessClass.title
          : ''
      ).filter((title) => title !== '') || []
      return {
        code: `${cob.code.split('-')[0]}${cob.code.split('-')[1] ? '-' + cob.code.split('-')[1] : ''}`,
        title: title,
        ancestorTitles: [cob.ancestorTitles && cob.ancestorTitles[0] ? cob.ancestorTitles[0] : ''],
        childrenTitles: [...childrenTitles]
      }
    })
    // create a list of unique cob codes which can appear on the result list (no minor cobs)
    // the minor cobs should only be available under other parent previously selected
    const uniqueSearchableCobCodes = [...new Set(this.businessClasses
      .map(cob => cob.code.split('-').length === 3 ? `${cob.code.split('-')[0]}-${cob.code.split('-')[1]}` : cob.code
      ))]
    const isNAICSWithChildren = (cob: COB) => cob.childrenTitles?.length &&
     cob.childrenTitles?.length > 0 && cob.code.split('-').length === 1

    // filter out duplicates and NAICS containing children because the children are searchable
    return uniqueSearchableCobCodes
      .map(o => searchableCobs.find(cob => cob.code === o && !isNAICSWithChildren(cob)))
      .filter((item): item is COB => !!item)
  }

  get google () {
    return gmapApi
  }

  get isNAICSInputValue (): boolean {
    return /^-?\d+$/.test(this.primaryInputValue)
  }

  get RequestedTypesOfInsurance (): string[] | null {
    return (binderModule.answers.RequestedTypesOfInsurance as string[]) || null
  }

  get placeholderText () {
    return this.question?.ui?.placeholder || 'Enter keyword(s) or NAICS code'
  }

  get cannotFindMyBusinessOption (): Record<string, any> {
    return { title: 'Not otherwise classified', code: '000000' }
  }

  get isCannotFindMyBusinessSelected () {
    return this.majorSelected && this.majorSelected.code === '000000'
  }

  get showSuggestions () {
    return (
      !this.majorSelected &&
      !this.majorOptionsOpen &&
      this.suggestionOptions.length &&
      this.suggestionsOpen
    )
  }

  get showSelectedItem () {
    return (
      this.majorSelected &&
      !this.isCannotFindMyBusinessSelected &&
      !this.majorOptionsOpen
    )
  }

  get showApplyToAllLocations () {
    return (
      this.question.id === 'Location1ClassOfBusiness' &&
      this.currentAnswer &&
      this.numberOfLocations > 1
    )
  }

  get showError () {
    return (
      this.error && this.minorOptions.length === 0 && this.primaryInputValue === ''
    )
  }

  get showCannotFindMyBusiness () {
    const isSelectedCobWithSecondaryOptions =
      this.majorSelected && this.minorOptions.length
    return (
      !this.isCannotFindMyBusinessSelected &&
      this.cannotFindMyBusinessOption &&
      (!this.majorSelected || isSelectedCobWithSecondaryOptions)
    )
  }

  beforeMount () {
    this.getCobsByProductSlug()
  }

  mounted () {
    this.$emit('override-hint', [])
    this.getSuggestions()
    // @ts-ignore
    Vue.$gmapApiPromiseLazy().then(() => {
      // @ts-ignore
      const map = new window.google.maps.Map(document.getElementById('map'))
      // @ts-ignore
      this.googlePlacesService = new window.google.maps.places.PlacesService(map)
    })
    this.getHistorySuggestion()
  }

  handleShowMoreOrLessCobs () {
    if (this.numberOfElementsResult > this.majorOptions.length) {
      const componentElement = this.$refs.classOfBusiness
      if (componentElement instanceof HTMLElement) VueScrollTo.scrollTo(componentElement, 100)
      VueScrollTo.scrollTo(this.$refs.classOfBusiness as HTMLElement, 100)
      this.numberOfElementsResult = 5
    } else {
      this.numberOfElementsResult = this.numberOfElementsResult + 5
    }
  }

  createSearchIndex (nodes: COB[]): Document<COB> {
    const index = new Document<COB>({
      document: {
        id: 'code',
        index: [
          {
            field: 'title',
            resolution: 9,
            preset: 'match',
            tokenize: 'forward'
          },
          {
            field: 'code',
            resolution: 8,
            preset: 'score',
            tokenize: 'forward'
          },
          {
            field: 'childrenTitles',
            resolution: 5,
            preset: 'match',
            tokenize: 'forward'
          },
          {
            field: 'ancestorTitles',
            resolution: 4,
            preset: 'match',
            tokenize: 'forward'
          }
        ]
      }
    })
    for (const node of nodes) {
      index.add(node)
    }
    return index
  }

  dismissResults (focused = false) {
    this.primaryInputValue = ''
    this.majorOptions = []
    this.minorOptions = []
    this.majorOptionsOpen = false
    this.resetDefaultState()
    if (focused) {
      this.focusInput()
    }
  }

  dismissSuggestions () {
    this.suggestionsOpen = !this.suggestionsOpen
    this.focusInput()
  }

  focusInput () {
    const componentElement = this.$refs.input
    if (componentElement instanceof HTMLInputElement) {
      VueScrollTo.scrollTo(componentElement, 200, {
        offset: -300
      })
    }

    const searchInput = this.$refs.input as HTMLInputElement
    searchInput.focus()
  }

  resetDefaultState () {
    this.primaryInputValue ? this.majorOptionsOpen = true : this.suggestionsOpen = true
    this.numberOfElementsResult = 5
    if (this.majorSelected) {
      this.majorSelected = null
      if (this.currentAnswer) this.$emit('update-answer', null)
    }
  }

  select (cob: COB) {
    this.majorSelected = cob
    this.majorOptionsOpen = false

    this.minorOptions = this.businessClasses?.map((businessClass) =>
      (businessClass?.ancestorTitles?.includes(cob.title) &&
      businessClass.code.split('-')[0] === cob.code.split('-')[0]) && businessClass)
      .filter((item): item is COB => !!item)

    if (this.minorOptions.length === 0) {
      this.$emit('update-answer', cob.code)
      VueScrollTo.scrollTo(this.$refs.classOfBusiness as HTMLElement, 100)
    }
  }

  selectSecondary (cob: COB) {
    this.$emit('update-answer', cob.code)
  }

  async searchHandler (inputValue: string, isSuggestions: boolean) {
    this.numberOfElementsResult = 5

    const searchedIndex = await this.searchIndex?.search(inputValue, {
      enrich: true,
      suggest: true
    })
    const mainOptionsIdsFound = searchedIndex
      ? [
        ...new Set(
          searchedIndex
            .map((searchIndexFieldResult) => searchIndexFieldResult.result)
            .flatMap((cobId) => cobId)
        )
      ]
      : []
    const matchingCobsByIds: COB[] = mainOptionsIdsFound
      .map(id => this.cobs.find(cob => cob && cob.code === id.toString()))
      .filter((item): item is COB => !!item)
    if (isSuggestions) {
      this.suggestionsOpen = true
      this.suggestionOptions = matchingCobsByIds || this.cobs
      this.$nextTick(() => {
        this.getHistorySuggestion()
      })
    } else {
      this.majorOptions = matchingCobsByIds || this.cobs
      this.majorOptionsOpen = true
    }
  }
}
