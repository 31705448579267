

























import { defineComponent, computed, onMounted, ref } from '@vue/composition-api'
import { Question, QuestionType } from '@/models/questionnaire'
import TextInput from '@/components/controls/text-input/index.vue'
import { cleanMask } from '@/helpers/utils'
export default defineComponent({
  name: 'SingleLineControlWrapper',
  components: {
    TextInput
  },
  props: {
    question: {
      type: Object as () => Question,
      required: true
    },
    inputHint: {
      type: String,
      default: ''
    },
    error: {
      type: String,
      default: ''
    },
    currentAnswer: {
      type: String || Number,
      default: ''
    },
    isReviewing: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update-answer'],
  setup (props, { emit }) {
    const answer = ref('')
    const inputType = computed(() => {
      switch (props.question?.type) {
        case QuestionType.DateYear:
          return 'number'
        default:
          return 'text'
      }
    })

    onMounted(() => {
      answer.value = props.currentAnswer ? props.currentAnswer as string : ''
    })

    const updateAnswerHandler = (value: string) => {
      emit('update-answer', cleanMask(value, props.question?.ui.mask))
    }

    const changeHandler = (event: Event) => {
      const value = (event.target as HTMLInputElement).value
      emit('update-answer', cleanMask(value, props.question?.ui.mask))
    }
    return {
      inputType,
      updateAnswerHandler,
      changeHandler,
      answer
    }
  }

})
