




































import { defineComponent } from '@vue/composition-api'
import { CheckIcon } from 'vue-feather-icons'
import IconBase from '@/components/icon-library/icon-base.vue'
import Sent from '@/components/icon-library/sent.vue'

export default defineComponent({
  name: 'SaveForLaterConfirmation',
  components: {
    Sent,
    IconBase,
    CheckIcon
  },
  props: {
    email: {
      type: String,
      required: true
    }

  },
  emits: ['close-drawer']
})
