




















































import { Component, Mixins, Prop, PropSync } from 'vue-property-decorator'
import BrizaMixin from '@/mixins'
import ErrorLabel from '@/components/controls/error-label/index.vue'
import { Question } from '@/models/questionnaire'

@Component({
  name: 'ToggleCheckbox',
  components: { ErrorLabel }
})
export default class ToggleCheckbox extends Mixins(BrizaMixin) {
  @PropSync('value', { type: String }) content!: string
  @Prop() question!: Question
  @Prop() controlId!: string
  @Prop() currentAnswer!: boolean
  @Prop({ default: false }) isReviewing!: boolean
  answer = false

  keyHandler (event: KeyboardEvent) {
    if (event.keyCode === 13) {
      (event.target as HTMLElement).click()
    }
  }

  mounted () {
    // Apply default:
    if (typeof this.currentAnswer === 'boolean') {
      this.answer = this.currentAnswer
    } else if (typeof this.question.default === 'boolean') {
      this.answer = this.question.default
      this.$emit('update-answer', this.question.default)
    }
  }
}
