

































import { Component, Prop, Vue } from 'vue-property-decorator'
import SideNavigationLayout from '@/layouts/side-navigation-layout.vue'
import { Section } from '@/models/layout'
import { binderModule } from '@/store/store-accessor'
import { scrollToSection } from '@/helpers/scroll'

@Component({
  name: 'StepCounter',
  components: {
    SideNavigationLayout
  }
})
export default class StepCounter extends Vue {
  @Prop(Number) readonly step!: number
  @Prop() layout!: Section[]

  get visibleSections () {
    return binderModule.visibleSections
  }

  get activeSection () {
    return binderModule.visibleSections[binderModule.visibleSections.length - 1]
  }

  isVisited (sectionId: string): boolean {
    return this.visibleSections.includes(sectionId)
  }

  navSection (sectionId: string) {
    scrollToSection(sectionId)
  }
}
