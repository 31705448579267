




























































































import { Component, Prop, Vue } from 'vue-property-decorator'
import TextInput from '@/components/controls/text-input/index.vue'
import ErrorLabel from '@/components/controls/error-label/index.vue'
import Select from '@/components/controls/select/index.vue'
import { stateList } from '@/models/stateList'
import { Address } from '@/models/questionnaire'

@Component({
  name: 'ManualAddress',
  components: { ErrorLabel, TextInput, Select }
})
export default class ManualAddress extends Vue {
  @Prop() address!: Address
  @Prop() isManual!: boolean
  @Prop() onAddressChange!: (address: Address) => {}

  unitNumber: string | undefined = ''

  addressValidator = {
    street: false,
    city: false,
    region: false,
    postalCode: false
  }

  mounted () {
    this.$nextTick(function () {
      this.checkManualValidation()
    })
  }

  updated () {
    this.$nextTick(function () {
      this.checkManualValidation()
    })
  }

  get statesList () {
    return stateList
  }

  handleInputChange () {
    this.onAddressChange({
      ...this.address,
      ...(this.unitNumber) && {
        secondary: this.unitNumber
      }
    })
  }

  handleStateChange (key: string) {
    this.address.region = key
  }

  checkManualValidation () {
    this.addressValidator.street = this.address.street === ''
    this.addressValidator.city = this.address.city === ''
    this.addressValidator.region = this.address.region === ''
    this.addressValidator.postalCode = this.address.postalCode === '' || this.address.postalCode.length < 5
  }
}
