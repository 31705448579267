
























import { Component, Prop, Vue } from 'vue-property-decorator'
import { renderMarkdown } from '@/helpers/markdown'
import { InfoIcon } from 'vue-feather-icons'

/**
 * Note: this component is just a temporaty MVP solution for
 * fallback parsing of the complext (text rich) questions.
 * The will be removed when the curaiton of qustions will be
 * done, post MVP.
 */
@Component({
  name: 'QuestionLabelParsed',
  components: {
    InfoIcon
  }
})
export default class QuestionLabelParsed extends Vue {
  /**
   * Raw question text.
   */
  @Prop() input!: string

  // Parsed references.
  question = ''
  body = ''

  /**
   * Parse the input into question text and spread body elements.
   * 2 chunks = questinon text and question description.
   * 2+ chunkds = quiestion text and list of items in the description.
   */
  mounted () {
    const chunks = this.input.split('\n').filter((c) => c.length > 0 && c.match(/^\s+$/) === null)
    if (chunks.length === 0) {
      return
    }

    this.question = this.renderMarkdown(chunks[0], true)
    switch (chunks.length) {
      case 1:
        break
      case 2:
        this.body = this.renderMarkdown(chunks[1])
        break
      default:
        this.body = `
          <ul class="question-description-list">
            ${chunks.slice(1).map((e) => `<li>${this.renderMarkdown(e.trim(), true)}</li>`).join('')}
          </ul>
        `
        break
    }
  }

  /**
   * Render markdown.
   * @param input Markdown text.
   * @param trimParagraph Remove wrapper <p> produced by rendering predicate.
   */
  renderMarkdown (input: string, trimParagraph = false): string {
    const output = renderMarkdown(input.trim())
    if (trimParagraph) {
      return output.replace(/<\/?p>/g, '')
    }
    return output
  }
}
