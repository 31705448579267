











import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'ErrorLabel',
  props: {
    error: {
      type: String,
      default: ''
    },
    group: {
      type: Boolean,
      default: false
    },
    control: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    const isToggleGroup = computed(() => {
      return props.group && props.control === 'toggle'
    })
    return { isToggleGroup }
  }
})
