




















import { MoreVerticalIcon } from 'vue-feather-icons'
import { binderModule } from '@/store/store-accessor'
import { defineComponent } from '@vue/composition-api'
export default defineComponent({
  name: 'ReviewEditQuestionToggle',
  components: { MoreVerticalIcon },
  props: {
    isHovered: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    const toggleReviewOverlay = () => {
      binderModule.setReviewOverlay()
    }
    return { toggleReviewOverlay }
  }
})

