























































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { cleanMask } from '@/helpers/utils'
import { AlertTriangleIcon, CheckIcon } from 'vue-feather-icons'

@Component({
  name: 'TextInput',
  components: {
    AlertTriangleIcon,
    CheckIcon
  }
})
export default class TextInput extends Vue {
  @Prop() label!: string
  @Prop() controlId!: string
  @Prop({ default: 'text' }) type!: string
  @Prop() error!: string
  @Prop() inputHint: string | undefined
  @Prop() value!: string
  @Prop() placeholder !: string
  @Prop({ default: '' }) mask!: string
  @Prop() innerStyle!: Record<string, unknown>
  @Prop({ default: false }) isReviewing!: boolean

  get step (): number | string {
    return this.type === 'number' ? 1 : 'any'
  }

  get computedMask () {
    return this.mask && this.mask.replace(/_/g, '#')
  }

  @Emit('keyup')
  keyup ($event: KeyboardEvent) {
    $event.stopPropagation()
  }

  @Emit('keydown')
  keydown ($event: KeyboardEvent) {
    if (this.type === 'number') {
      const keyCode = $event.keyCode
      // Allowing only digits, backspace and tab
      if ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105) || keyCode === 8 || keyCode === 9) {
        return true
      } else {
        $event.preventDefault()
      }
    }
  }

  maskedBlurHander (event: Event) {
    const value = (event.target as HTMLInputElement).value
    this.$emit('update-answer', cleanMask(value, this.mask))
    this.$emit('blur', event)
  }
}
