/**
 * Collection of type check functions.
 */

import { AnswerValue } from '@/models/questionnaire'

/**
 * Is Object predicate.
 * @param value tested value
 * @return result of the test
 */
export function isObject (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
): boolean {
  return (
    value !== undefined &&
    value !== null &&
    typeof value === 'object' &&
    value.constructor === Object
  )
}

/**
 * Is String predicate.
 * @param value tested value.
 * @return result of the test
 */
export function isString (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
): value is string {
  return typeof value === 'string' || value instanceof String
}

/**
 * Is Number predicate.
 * @param value tested value
 * @return result of the test
 */
export function isNumber (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
): value is number {
  return typeof value === 'number' && isFinite(value)
}

/**
 * Is Integer predicate.
 * @param value tested value
 * @return result of the test
 */
export function isInteger (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
): value is number {
  return Number.isInteger(value)
}

/**
 * Is Array predicate.
 * @param value tested value
 * @return result of the test
 */
export function isArray (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): value is Array<any> {
  return Array.isArray(value)
}

/**
 * Is Boolean predicate.
 * @param value tested value.
 * @return result of the test
 */
export function isBoolean (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
): value is boolean {
  return typeof value === 'boolean'
}

/**
 * Is Array of string predicate.
 * @param value tested value
 * @return result of the test
 */
export function isStringArray (value: unknown): value is Array<string> {
  return (
    Array.isArray(value) &&
    !value.some((item: unknown) => typeof item !== 'string')
  )
}

export const UnansweredValue = null

export function isAddress (answer: AnswerValue): answer is AnswerValueAddress {
  return (
    isObject(answer) &&
    ['street', 'city', 'region', 'postalCode', 'country'].every(
      (key) => key in Object(answer)
    )
  )
}

/**
 * Answer value for questions for type "Address"
 */
export interface AnswerValueAddress {
  /** Object Index Signature to access object properties by key. */
  [key: string]: string | undefined
  street: string
  secondary?: string
  city: string
  region: string // state for US
  postalCode: string
  county?: string
  country: string
}
