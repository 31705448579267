var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.longitude && _vm.latitude)?_c('GmapMap',{staticStyle:{"height":"108px"},attrs:{"tab-index":"-1","center":{lat: _vm.latitude, lng: _vm.longitude},"zoom":15,"options":{
    zoomControl: false,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
    disableDefaultUi: false
  }}},[_c('GmapMarker',{attrs:{"position":{ lat: _vm.latitude, lng: _vm.longitude}}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }