




























import { defineComponent } from '@vue/composition-api'
import { COB } from '@/models/cob'
export default defineComponent({
  name: 'BusinessClassCard',
  props: {
    businessClass: {
      type: Object,
      default: () => { return {} as COB }
    }
  },
  emits: ['select'],
  setup (props, context) {
    const handleSelect = () => {
      context.emit('select')
    }
    return {
      handleSelect
    }
  }
})
