




























import { Component, Prop, Mixins } from 'vue-property-decorator'
import { AnswerValue, QuestionOption } from '@/models/questionnaire'
import { binderModule } from '@/store/store-accessor'
import BrokerageMixin from '@/mixins/brokerage-mixin'

@Component({
  name: 'ProductType'
})
export default class ProductType extends Mixins(BrokerageMixin) {
  @Prop({ default: false }) readonly multi!: boolean;
  @Prop() options!: QuestionOption[];
  @Prop() controlId!: string;
  @Prop() currentAnswer !: AnswerValue
  answer: string[] = [];

  mounted () {
    this.answer = this.currentAnswer ? this.currentAnswer as string[] : []

    if (this.options.length === 1) {
      this.updateSelection(this.options[0]?.key as string)
    }
  }

  get productOptions (): QuestionOption[] {
    return this.options
  }

  /**
   * method to update the answers object with the selected keys only
   * if key is found, it will be deselected/removed
   * @param key
   */
  updateSelection (key: string) {
    binderModule.setActiveControl(this.controlId)
    const keyIndex = this.answer.indexOf(key)
    if (this.multi) {
      keyIndex !== -1
        ? this.answer.splice(keyIndex, 1)
        : this.answer.push(key)
    } else {
      this.answer = [key]
    }
    this.$emit('update-answer', this.answer)
  }
}
